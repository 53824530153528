<script>
  import { get } from 'vuex-pathify'

  /**
   * A tab bar, where each tab points to a particular route and is visibly selected when that route is the current route or a parent of it.
   */
  export default {
    props: {
      /** An array of tabs, which should be objects with `to` and `label` keys. */
      tabs: {
        type: Array,
        required: true,
      },
    },
    computed: {
      ...get(['route']),
    },
    methods: {
      isTabSelected(tab) {
        if (!this.$router) {
          return false
        }
        const resolvedRoute = this.$router.resolve(tab.to)?.route
        return resolvedRoute && this.route.path.startsWith(resolvedRoute.path)
      },
    },
  }
</script>

<template>
  <ul
    class="flex justify-center md:justify-start items-center h-12 md:px-10 bg-white text-sm"
    role="tablist"
  >
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      class="mx-2 md:mx-3 flex h-full"
      role="presentation"
    >
      <BaseLink
        role="tab"
        :aria-selected="isTabSelected(tab) ? 'true' : 'false'"
        :to="tab.to"
        :class="`flex justify-center items-center px-4 md:px-6 border-b-2 ${
          isTabSelected(tab) ? 'border-green' : ''
        } hover:border-green transition duration-200`"
      >
        {{ tab.label }}
      </BaseLink>
    </li>
  </ul>
</template>
